import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Box, Grid2 as Grid, InputLabel, TextField } from '@mui/material'
import Stack from '@mui/material/Stack'
import {
  CreateExtManagement,
  CreateExtManagementInput,
  CreateExtManagementVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button'
import Modal from 'src/components/Modal/Modal'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { CREATE_EXT_MANAGEMENT } from 'src/lib/queries/PropertyGenie/PropertyLocations'

interface FormProps {
  openModal: boolean
  setOpenCreatePropertyModal: Dispatch<SetStateAction<boolean>>
}

const PropertyGenieInsertPropertyModal: React.FC<FormProps> = ({
  openModal,
  setOpenCreatePropertyModal,
}) => {
  const [propertyStreet, setPropertyStreet] = useState<string>('')
  const [propertySuburb, setPropertySuburb] = useState<string>('')
  const [propertyPostcode, setPropertyPostcode] = useState<string>('')
  const [propertyState, setPropertyState] = useState<string>('')
  const [propertyBedrooms, setPropertyBedrooms] = useState<number>(0)
  const [propertyBathrooms, setPropertyBathrooms] = useState<number>(0)
  const [propertyCarSpaces, setPropertyCarSpaces] = useState<number>(0)
  const [propertyLandSize, setPropertyLandSize] = useState<number>(0)
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const { trackEvent } = useAnalytics()

  const handleInsert = async () => {
    const payload: CreateExtManagementInput = {
      isArchived: false,
      address: `${propertyStreet} ${propertySuburb} ${propertyState} ${propertyPostcode}`,
      suburb: propertySuburb || '',
      postcode: propertyPostcode || '',
      bedrooms: propertyBedrooms || 0,
      bathrooms: propertyBathrooms || 0,
      carSpaces: propertyCarSpaces || 0,
      landSize: propertyLandSize || 0,
    }
    await CreateExtManagement({
      variables: {
        input: payload,
      },
    })
  }

  const [CreateExtManagement] = useMutation<
    CreateExtManagement,
    CreateExtManagementVariables
  >(CREATE_EXT_MANAGEMENT, {
    onCompleted: () => {
      setIsGenerating(false)
      setOpenCreatePropertyModal(false)
      toast.success('Property created successfully')
    },
    refetchQueries: [
      'PropertyLocationsQuery',
      'FindPropertyGenieListingFormQuery',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
      setIsGenerating(false)
    },
  })

  useEffect(() => {
    setPropertyStreet('')
    setPropertySuburb('')
    setPropertyPostcode('')
    setPropertyState('')
    setPropertyBedrooms(0)
    setPropertyBathrooms(0)
    setPropertyCarSpaces(0)
    setPropertyLandSize(0)
    setIsGenerating(false)
  }, [openModal])

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenCreatePropertyModal(false)
        trackEvent('Ad Generator', 'close insert property modal')
      }}
      title={'Insert New Property'}
    >
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 12 }}>
            <Stack padding={'20px'}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 4, sm: 4 }}>
                  <InputLabel>Address</InputLabel>
                </Grid>
                <Grid size={{ xs: 8, sm: 8 }}>
                  <TextField
                    type="text"
                    label="Street"
                    value={propertyStreet}
                    placeholder={'Eg: 1 Smith Street'}
                    onChange={(e) => setPropertyStreet(e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid size={{ xs: 4, sm: 4 }}></Grid>

                <Grid size={{ xs: 4, sm: 4 }}>
                  <TextField
                    label="Suburb"
                    type="text"
                    value={propertySuburb}
                    placeholder={'Eg: Smithfield'}
                    onChange={(e) => setPropertySuburb(e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid size={{ xs: 2, sm: 2 }}>
                  <TextField
                    label="State"
                    type="text"
                    value={propertyState}
                    placeholder={'Eg: QLD'}
                    onChange={(e) => setPropertyState(e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid size={{ xs: 2, sm: 2 }}>
                  <TextField
                    type="number"
                    label="Postcode"
                    placeholder={'Eg: 4000'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={propertyPostcode}
                    onChange={(e) => setPropertyPostcode(e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid size={{ xs: 4, sm: 4 }}>
                  <InputLabel>Property Details</InputLabel>
                </Grid>
                <Grid size={{ xs: 2, sm: 2 }}>
                  <Stack direction={'row'}>
                    <TextField
                      type="number"
                      label="Bedrooms"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      value={propertyBedrooms}
                      onChange={(e) =>
                        setPropertyBedrooms(parseInt(e.target.value, 10))
                      }
                      fullWidth
                      size="small"
                    />
                  </Stack>
                </Grid>
                <Grid size={{ xs: 2, sm: 2 }}>
                  <Stack direction={'row'}>
                    <TextField
                      type="number"
                      label="Bathrooms"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      value={propertyBathrooms}
                      onChange={(e) =>
                        setPropertyBathrooms(parseInt(e.target.value, 10))
                      }
                      fullWidth
                      size="small"
                    />
                  </Stack>
                </Grid>
                <Grid size={{ xs: 2, sm: 2 }}>
                  <Stack direction={'row'}>
                    <TextField
                      type="number"
                      label="Cars"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      value={propertyCarSpaces}
                      onChange={(e) =>
                        setPropertyCarSpaces(parseInt(e.target.value, 10))
                      }
                      fullWidth
                      size="small"
                    />
                  </Stack>
                </Grid>
                <Grid size={{ xs: 2, sm: 2 }}>
                  <Stack direction={'row'}>
                    <TextField
                      type="number"
                      label="Land Size"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      value={propertyLandSize}
                      onChange={(e) =>
                        setPropertyLandSize(parseInt(e.target.value, 10))
                      }
                      fullWidth
                      size="small"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        <div className="float-right py-4">
          <Button
            variant="contained"
            onClick={() => {
              setIsGenerating(true)
              handleInsert()
              trackEvent('Ad Generator', 'insert property')
            }}
            loading={isGenerating}
          >
            Insert
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default PropertyGenieInsertPropertyModal
