export const GENERATE_PROPERTY_CONTENT = gql`
  mutation generatePropertyContent(
    $extManagementId: Int
    $selectedKeywords: String
    $selectedThemes: String
    $selectedSchools: String
    $selectedTemplate: Int
    $extListingId: Int
  ) {
    generatePropertyContent(
      extManagementId: $extManagementId
      extListingId: $extListingId
      selectedKeywords: $selectedKeywords
      selectedThemes: $selectedThemes
      selectedSchools: $selectedSchools
      selectedTemplate: $selectedTemplate
    ) {
      id
      approvedBy
      approved
      status
      createdAt
      updatedAt
      createdBy
      createdByUser {
        id
        name
      }
      template {
        id
        name
      }
      ExtManagement {
        id
        address
      }
      ExtListing {
        id
        address
      }
    }
  }
`
export const UPDATE_PROPERTY_CONTENT = gql`
  mutation UpdatePropertyContent(
    $id: Int!
    $input: UpdatePropertyContentInput!
  ) {
    updatePropertyContent(id: $id, input: $input) {
      id
    }
  }
`
