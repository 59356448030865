/// <reference types="@types/google.maps" />
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  Autocomplete,
  Box,
  Grid2 as Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { captureEvent } from '@sentry/browser'
import { FLAG_FEATURES } from 'api/src/common/enums'
import { PropertyTemplate } from 'types/graphql'
import { useLocalStorage } from 'usehooks-ts'

import Button from 'src/components/Library/Button'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Modal from 'src/components/Modal/Modal'
import PropertyGenieInsertPropertyModal from 'src/components/PropertyGenie/PropertyGenieInsertPropertyModal'
import { themeType } from 'src/components/PropertyGenie/PropertyGenieMainPage'
import VirtualizedAutocomplete from 'src/components/PropertyGenie/VirtualizedAutocomplete'
import useQuota from 'src/lib/hooks/Quota/useQuota'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import BathIcon from 'src/lib/icons/BathIcon/BathIcon'
import BedIcon from 'src/lib/icons/BedIcon/BedIcon'
import CarIcon from 'src/lib/icons/CarIcon/CarIcon'
import RulerIcon from 'src/lib/icons/RulerIcon/RulerIcon'
import { useAuth } from 'src/Providers'

import useGoogleMaps from './useGoogleMaps'

interface FormProps {
  updatedProperty: any
  propertyTemplates: PropertyTemplate[]
  open: boolean
  handleClose: () => void
  generatePropertyContent: (args: any) => any
  selectedKeywords: string[]
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>
  selectedThemes: themeType[]
  setSelectedThemes: Dispatch<SetStateAction<themeType[]>>
  propertyList: any
  sourceType: string
  setVersion: Dispatch<SetStateAction<number>>
  version: number
  setSelectedContent: Dispatch<SetStateAction<any>>
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
}

type schoolType = {
  name?: string
  distance: string
}

const PropertyGenieListingForm: React.FC<FormProps> = ({
  propertyTemplates,
  updatedProperty,
  open,
  handleClose,
  generatePropertyContent,
  propertyList,
  selectedKeywords,
  setSelectedKeywords,
  selectedThemes,
  setSelectedThemes,
  sourceType,
  setVersion,
  version,
  setSelectedContent,
  setOpenDrawer,
}) => {
  const { currentUser } = useAuth()
  const { mapsIsReady, mapsHasError, googleMaps } = useGoogleMaps()
  const memberId = currentUser.userData.activeMembership.id
  const STORAGE_KEY = `memberId-${memberId}-propertyGenieLastTemplate`

  const { trackEvent } = useAnalytics()

  const [lastUsedTemplate, setLastUsedTemplate] = useLocalStorage(
    STORAGE_KEY,
    null,
  )

  const [availableSchools, setAvailableSchools] = useState<schoolType[]>([])
  const [selectedSchools, setSelectedSchools] = useState<schoolType[]>([])
  const [templateValue, setTemplateValue] = useState(null)
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [openCreatePropertyModal, setOpenCreatePropertyModal] =
    useState<boolean>(false)
  const [googleSecondarySchools, setGoogleSecondarySchools] = useState<
    schoolType[]
  >([])
  const [googlePrimarySchools, setGooglePrimarySchools] = useState<
    schoolType[]
  >([])

  const { isUsageBlocked } = useQuota({
    feature: FLAG_FEATURES.PROPERTY_GENIE,
  })

  type ReactEvent = React.SyntheticEvent<Element, Event>
  const handleKeywordInputChange = (_: ReactEvent, newValues: string[]) => {
    setSelectedKeywords(newValues)
  }

  const handleThemeInputChange = (_: ReactEvent, newValues: themeType[]) => {
    setSelectedThemes(newValues)
  }

  const handleSchoolInputChange = (_: ReactEvent, newValues: schoolType[]) => {
    setSelectedSchools(newValues)
  }

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.currentTarget.value) {
      event.preventDefault()
      const newKeyword = event.currentTarget.value
      if (!selectedKeywords.includes(newKeyword)) {
        const newKeywordsArray = [...selectedKeywords, newKeyword]
        setSelectedKeywords(newKeywordsArray)
      }
    }
  }

  const handleGenerate = async () => {
    const schoolNames: string[] = []
    if (selectedSchools.length !== 0) {
      for (const school of selectedSchools) {
        schoolNames.push(school.name)
      }
    } else {
      schoolNames.push('')
    }

    const record = await generatePropertyContent({
      variables: {
        extManagementId:
          selectedProperty.type === 'management' ? selectedProperty.id : null,
        extListingId:
          selectedProperty.type === 'listing' ? selectedProperty.id : null,
        selectedKeywords: selectedKeywords.join(' & '),
        selectedSchools: schoolNames.join(' & '),
        selectedTemplate: templateValue?.id || 0,
        selectedThemes: selectedThemes.map((theme) => theme.label).join(' & '),
      },
    })
    if (setVersion) {
      setVersion(version + 1)
    }

    const sourceData =
      record.data.generatePropertyContent.ExtManagement ||
      record.data.generatePropertyContent.ExtListing
    const sourceType =
      sourceData === record.data.generatePropertyContent.ExtManagement
        ? 'management'
        : 'listing'

    const returnRecord = {
      ...record.data.generatePropertyContent,
      sourceData: { ...sourceData, sourceType },
    }

    setSelectedContent(returnRecord)
    setOpenDrawer(true)
  }

  const themeList: themeType[] = [
    { id: 0, label: 'Open Living' },
    { id: 1, label: 'Modern Design' },
    { id: 2, label: 'Natural Light' },
    { id: 3, label: 'Tranquil Retreat' },
    { id: 4, label: 'Family-Friendly' },
    { id: 5, label: 'Urban Oasis' },
    { id: 6, label: 'Vintage Charm' },
    { id: 7, label: 'Newly Renovated' },
    { id: 8, label: 'Renovators Dream' },
  ]

  const googleSearch = (
    googleService: google.maps.places.PlacesService,
    type: string,
    setValue: React.Dispatch<React.SetStateAction<schoolType[]>>,
    location: google.maps.places.PlaceResult,
  ) => {
    googleService.nearbySearch(
      {
        location: location?.geometry.location,
        type: type,
        rankBy: window.google.maps.places.RankBy.DISTANCE,
      },
      (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        if (status !== 'OK' || !results) return

        const result = results.map((result) => {
          return {
            ...result,
            distance: (
              googleMaps.geometry.spherical.computeDistanceBetween(
                location?.geometry.location,
                result.geometry.location,
              ) / 1000
            ).toFixed(2),
          }
        })

        setValue(result)
      },
    )
  }

  const handleGoogleSearch = () => {
    if (mapsIsReady) {
      const sydney = new googleMaps.LatLng(-33.867, 151.195)

      const map = new googleMaps.Map(document.getElementById('map'), {
        center: sydney,
        zoom: 15,
      })

      const googleService = new googleMaps.places.PlacesService(map)
      const request = {
        query: selectedProperty.address,
        fields: ['name', 'geometry', 'formatted_address', 'place_id'],
      }

      googleService.findPlaceFromQuery(request, function (results, status) {
        if (status === googleMaps.places.PlacesServiceStatus.OK) {
          googleSearch(
            googleService,
            'primary_school',
            setGooglePrimarySchools,
            results[0],
          )
          googleSearch(
            googleService,
            'secondary_school',
            setGoogleSecondarySchools,
            results[0],
          )
        }
      })
    } else if (mapsHasError) {
      const error = new Error('Google Maps API not loaded')
      captureEvent({
        message: 'Google Maps API not loaded',
        level: 'error',
        tags: { component: 'PropertyGenieListingForm' },
        extra: { error: error },
      })

      // eslint-disable-next-line no-console
      console.log(error)
    } else {
      // eslint-disable-next-line no-console
      console.log('Google Maps API not ready')
    }
  }

  const handleSelectAddress = (searchString) => {
    if (!searchString) {
      setSelectedProperty(null)
      setSelectedSchools([])
      setAvailableSchools([])
    } else {
      setSelectedProperty(searchString)
    }
  }

  useEffect(() => {
    const newSchools = [...googlePrimarySchools, ...googleSecondarySchools]

    const uniqueNames = new Set()

    const allSchoolsIndexed = newSchools.map((school, index) => {
      return { ...school, value: index }
    })

    const uniqueSchools = allSchoolsIndexed.filter((school) => {
      if (!uniqueNames.has(school.name)) {
        uniqueNames.add(school.name)
        return true
      }
      return false
    })

    setAvailableSchools(uniqueSchools)
  }, [googlePrimarySchools, googleSecondarySchools])

  useEffect(() => {
    if (selectedProperty) {
      handleGoogleSearch()
    }
  }, [selectedProperty])

  useEffect(() => {
    if (updatedProperty !== null) {
      setSelectedProperty({
        id: updatedProperty.id,
        address: `${updatedProperty.address}`,
        bedrooms: updatedProperty.bedrooms,
        bathrooms: updatedProperty.bathrooms,
        carSpaces: updatedProperty.carSpaces,
        landSize: updatedProperty.landSize,
        type: sourceType,
      })
    }
    setIsGenerating(false)
    setSelectedKeywords([])
    setSelectedThemes([])
    setSelectedSchools([])
    setTemplateValue(null)
  }, [updatedProperty, open])

  useEffect(() => {
    if (lastUsedTemplate !== null) {
      setLastUsedTemplate(lastUsedTemplate?.id)
    }
  }, [lastUsedTemplate])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      dialogClassName={'!p-0 max-w-[1200px]'}
      title={`Generate ${updatedProperty ? 'Modified' : 'New'} Ad`}
      disablePortal={false}
      loading={isGenerating}
    >
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        {isGenerating ? (
          <div className="pb-5" data-testid="ad-gen-generating-modal">
            <div className={'flex h-40 w-full items-center justify-center'}>
              <LoadingSpinner />
            </div>
            <Typography className="animate-pulse text-center text-2xl text-slate-500">
              Generating Your Ad...
            </Typography>
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 12 }}>
              <Stack padding={'20px'}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel>Listing Address</InputLabel>
                  </Grid>
                  <Grid size={{ xs: 7, sm: 7 }}>
                    {!updatedProperty ? (
                      <>
                        <VirtualizedAutocomplete
                          label={'Search'}
                          disabled={updatedProperty !== null}
                          value={selectedProperty}
                          options={[...propertyList].sort((a, b) => {
                            if (a.address < b.address) {
                              return -1
                            }
                            if (a.address > b.address) {
                              return 1
                            }
                            return 0
                          })}
                          onChange={(_, newValue) => {
                            handleSelectAddress(newValue)
                          }}
                          icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                          dataTestId={`property-search-${selectedProperty?.address}`}
                        />
                      </>
                    ) : (
                      <InputLabel>{updatedProperty.address}</InputLabel>
                    )}
                  </Grid>
                  <Grid size={{ xs: 1, sm: 1 }}>
                    {!updatedProperty && (
                      <Tooltip title={'Add A New Property'}>
                        <div>
                          <Button
                            // startIcon={<SparklesIcon className="w-5 h-5" />}
                            className="min-w-[0] rounded-lg"
                            disabled={isUsageBlocked}
                            onClick={() => {
                              setOpenCreatePropertyModal(true)
                              trackEvent(
                                'Ad Generator',
                                'click insert property',
                              )
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid size={{ xs: 4, sm: 4 }}></Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <Stack spacing={0.5} alignItems="center" direction={'row'}>
                      <BedIcon className={'h-5 w-5 text-gray-300'} />
                      <p className={'text-gray-500'}>
                        {selectedProperty?.bedrooms}{' '}
                      </p>
                      <p></p>
                      <BathIcon className={'h-5 w-5 text-gray-300'} />
                      <p className={'text-gray-500'}>
                        {selectedProperty?.bathrooms}
                      </p>
                      <p></p>
                      <CarIcon className={'h-5 w-5 text-gray-300'} />
                      <p className={'text-gray-500'}>
                        {selectedProperty?.carSpaces}
                      </p>
                      <p></p>
                      <RulerIcon className={'h-5 w-5 text-gray-300'} />
                      <p className={'text-gray-500'}>
                        {selectedProperty?.landSize || '0'}
                      </p>
                    </Stack>
                  </Grid>
                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel>Keywords</InputLabel>
                  </Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <Autocomplete
                      multiple
                      freeSolo
                      ChipProps={{ size: 'small' }} // Setting chip size explicitly
                      options={[]}
                      value={selectedKeywords}
                      onChange={(event, newValues) =>
                        handleKeywordInputChange(event, newValues)
                      }
                      onKeyDown={handleEnter}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Keywords"
                          variant="outlined"
                        />
                      )}
                      data-testid="ad-gen-keyword-autocomplete"
                    />
                  </Grid>
                  <Grid size={{ xs: 4, sm: 4 }}></Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <Stack direction={'row'} spacing={1}>
                      <InputLabel
                        style={{
                          fontSize: '12px',
                        }}
                      >
                        Press Enter to add new keyword
                      </InputLabel>
                    </Stack>
                  </Grid>

                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel>Themes</InputLabel>
                  </Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <Autocomplete
                      ChipProps={{ size: 'small' }} // Setting chip size explicitly
                      multiple
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      options={themeList}
                      value={selectedThemes}
                      onChange={(event, newValues) =>
                        handleThemeInputChange(event, newValues)
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Themes"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel>Schools</InputLabel>
                  </Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <Autocomplete
                      ChipProps={{ size: 'small' }} // Setting chip size explicitly
                      multiple
                      options={availableSchools}
                      value={selectedSchools}
                      onChange={(event, newValues) =>
                        handleSchoolInputChange(event, newValues)
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Schools"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel data-testid="select-template-label">
                      Select a Template
                    </InputLabel>
                  </Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <Autocomplete
                      options={propertyTemplates}
                      value={templateValue}
                      onChange={(_, newValue) => setTemplateValue(newValue)}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Templates"
                          variant="outlined"
                        />
                      )}
                      data-testid="select-template"
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        )}
        {!isGenerating && (
          <div className="float-right py-4">
            <Stack direction={'row'} spacing={3}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose()
                }}
                loading={isGenerating}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setIsGenerating(true)
                  handleGenerate()
                }}
                disabled={selectedProperty === null}
                loading={isGenerating}
                buttonDataTestId="generate-new-listing-button"
              >
                Generate
              </Button>
            </Stack>
          </div>
        )}
        <PropertyGenieInsertPropertyModal
          openModal={openCreatePropertyModal}
          setOpenCreatePropertyModal={setOpenCreatePropertyModal}
        />
      </Box>
    </Modal>
  )
}

export default PropertyGenieListingForm
