export const fieldTypes = [
  '{Property Address}',
  '{Property Street}',
  '{Social Post on the property listing for sale}',
  '{Property Suburb}',
  '{Property State}',
  '{Property Postcode}',
  '{Car Spaces}',
  '{Bedrooms}',
  '{Bathrooms}',
  '{Property Description}',
  '{Property Features}',
  '{One Sentence about property}',
  '{Information about the property}',
  '{information about the area}',
  '{information about the suburb}',
  '{information about the city}',
  '{Property Feature}',
  '{Property Features}',
  '{list of key features}',
  '{short hook}',
  '{short summary about property}',
  '{nearby schools}',
]
