import { Dispatch, FC, SetStateAction, useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'

import PropertyGenie from 'src/components/PropertyGenie/PropertyGenie'
import { themeType } from 'src/components/PropertyGenie/PropertyGenieMainPage'
import { PropertyTemplate } from 'src/components/PropertyGenie/PropertyLocationsCell'

const QUERY_MANAGEMENT = gql`
  query FindManagementQuery($id: Int!) {
    propertyLocation: extManagement(id: $id) {
      id
      address
      bedrooms
      bathrooms
      carSpaces
      landSize
      integrationType
      propertyImageUrl
      propertyContents {
        id
        content
        status
        approved
        approvedBy
        createdByUser {
          id
          name
        }
        approvedByUser {
          id
          name
        }
        createdAt
        createdBy
        keywords
        keyThemes
        keySchools
        template {
          name
        }
      }
    }
  }
`

const QUERY_LISTING = gql`
  query FindListingQuery($id: Int!) {
    propertyLocation: extListing(id: $id) {
      id
      address
      bedrooms
      bathrooms
      carSpaces
      landSize
      integrationType
      propertyImageUrl
      propertyContents {
        id
        content
        status
        approved
        approvedBy
        createdByUser {
          id
          name
        }
        approvedByUser {
          id
          name
        }
        createdAt
        createdBy
        keywords
        keyThemes
        keySchools
        template {
          name
        }
      }
    }
  }
`

interface StatusTypes {
  value: string
  label: string
  color: string
}

export interface PropertyGenieCellProps {
  propertyTemplates: PropertyTemplate[]
  isUsageBlocked: boolean
  statusTypes: StatusTypes[]
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
  setOpenCreateAdModal: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
  generatePropertyContent: (property: any) => void
  selectedKeywords: string[]
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>
  selectedThemes: themeType[]
  setSelectedThemes: Dispatch<SetStateAction<themeType[]>>
  openCreateAdModal: boolean
  contentId: number
  sourceType: string
  openDrawer: boolean
  id: number
  setSelectedContent: Dispatch<SetStateAction<any>>
}

const PropertyGenieCell: FC<PropertyGenieCellProps> = ({
  sourceType,
  openDrawer,
  isUsageBlocked,
  propertyTemplates,
  statusTypes,
  setOpenDrawer,
  setOpenCreateAdModal,
  handleClose,
  generatePropertyContent,
  selectedKeywords,
  setSelectedKeywords,
  selectedThemes,
  setSelectedThemes,
  openCreateAdModal,
  contentId,
  id,
  setSelectedContent,
}) => {
  const [getManagement, { data: managementData }] =
    useLazyQuery(QUERY_MANAGEMENT)
  const [getListing, { data: listingData }] = useLazyQuery(QUERY_LISTING)

  useEffect(() => {
    if (!sourceType || !id) {
      return
    }
    if (sourceType === 'management') {
      getManagement({ variables: { id } })
    } else if (sourceType === 'listing') {
      getListing({ variables: { id } })
    }
  }, [openDrawer])

  return (
    <>
      {(managementData || listingData) && (
        <PropertyGenie
          contentId={contentId}
          openCreateAdModal={openCreateAdModal}
          isUsageBlocked={isUsageBlocked}
          property={
            managementData
              ? managementData.propertyLocation
              : listingData.propertyLocation
          }
          propertyTemplates={propertyTemplates}
          statusTypes={statusTypes}
          setOpenDrawer={setOpenDrawer}
          setOpenCreateAdModal={setOpenCreateAdModal}
          handleClose={handleClose}
          generatePropertyContent={generatePropertyContent}
          selectedKeywords={selectedKeywords}
          setSelectedKeywords={setSelectedKeywords}
          selectedThemes={selectedThemes}
          setSelectedThemes={setSelectedThemes}
          sourceType={sourceType}
          setSelectedContent={setSelectedContent}
        />
      )}
    </>
  )
}
export default PropertyGenieCell
