import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'

import PropertyGenieListingFormCell from 'src/components/PropertyGenie/PropertyGenieListingFormCell'
import { themeType } from 'src/components/PropertyGenie/PropertyGenieMainPage'
import { PropertyTemplate } from 'src/components/PropertyGenie/PropertyLocationsCell'

export interface PropertyGenerateModalProps {
  propertyTemplates: PropertyTemplate[]
  handleClose: () => void
  generatePropertyContent: (args: any) => any
  selectedKeywords: string[]
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>
  selectedThemes: themeType[]
  setSelectedThemes: Dispatch<SetStateAction<themeType[]>>
  openCreateAdModal: boolean
  sourceType: string
  viewCreateAdModal: boolean
  updatedProperty: any
  setVersion: (version: number) => void
  version: number
  setSelectedContent: Dispatch<SetStateAction<any>>
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
}

const PropertyGenerateModal: FC<PropertyGenerateModalProps> = ({
  propertyTemplates,
  openCreateAdModal,
  handleClose,
  generatePropertyContent,
  selectedKeywords,
  setSelectedKeywords,
  selectedThemes,
  setSelectedThemes,
  viewCreateAdModal,
  updatedProperty,
  sourceType,
  setVersion,
  version,
  setSelectedContent,
  setOpenDrawer,
}) => {
  useEffect(() => {}, [openCreateAdModal])

  return (
    <>
      {viewCreateAdModal && (
        <PropertyGenieListingFormCell
          sourceType={sourceType}
          propertyTemplates={propertyTemplates}
          updatedProperty={updatedProperty}
          open={openCreateAdModal}
          handleClose={handleClose}
          generatePropertyContent={generatePropertyContent}
          selectedKeywords={selectedKeywords}
          setSelectedKeywords={setSelectedKeywords}
          selectedThemes={selectedThemes}
          setVersion={setVersion}
          version={version}
          setSelectedThemes={setSelectedThemes}
          setSelectedContent={setSelectedContent}
          setOpenDrawer={setOpenDrawer}
        />
      )}
    </>
  )
}

export default PropertyGenerateModal
