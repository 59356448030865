export const CREATE_PROPERTY_TEMPLATE = gql`
  mutation createPropertyTemplate($input: CreatePropertyTemplateInput!) {
    createPropertyTemplate(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_PROPERTY_TEMPLATE = gql`
  mutation updatePropertyTemplate(
    $id: Int!
    $input: UpdatePropertyTemplateInput!
  ) {
    updatePropertyTemplate(id: $id, input: $input) {
      id
      name
    }
  }
`

export const DELETE_PROPERTY_TEMPLATE = gql`
  mutation deletePropertyTemplate($id: Int!) {
    deletePropertyTemplate(id: $id) {
      id
    }
  }
`
