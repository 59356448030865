import { FC, useEffect, useState } from 'react'

import {
  PropertyLocationsQuery,
  PropertyLocationsQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import PropertyGenieMainPage from 'src/components/PropertyGenie/PropertyGenieMainPage'
import { ReloadQuota } from 'src/lib/hooks/Quota/useQuota'

export const QUERY = gql`
  query PropertyLocationsQuery {
    propertyContents {
      id
      approvedBy
      approved
      status
      createdAt
      updatedAt
      createdBy
      createdByUser {
        id
        name
      }
      template {
        id
        name
      }
      ExtManagement {
        id
        address
        integrationType
      }
      ExtListing {
        id
        address
        integrationType
      }
    }

    propertyTemplates {
      id
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = ({ isUsageBlocked, reloadQuota }) => (
  <PropertyGenieMainPage
    propertyTemplates={[]}
    propertyContents={[]}
    isUsageBlocked={isUsageBlocked}
    reloadQuota={reloadQuota}
  />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)
type Props = CellSuccessProps<
  PropertyLocationsQuery,
  PropertyLocationsQueryVariables
> & { isUsageBlocked: boolean; reloadQuota: ReloadQuota }
export type PropertyGenieRefetch = Props['queryResult']['refetch']
export type PropertyTemplate = Props['propertyTemplates'][0]
export type PropertyContent = Props['propertyContents'][0]
export type PropertyWithSourceData = Omit<
  PropertyContent & {
    sourceData: (
      | PropertyContent['ExtManagement']
      | PropertyContent['ExtListing']
    ) & {
      sourceType: string
    }
  },
  'ExtManagement' | 'ExtListing'
>

export const Success: FC<Props> = ({
  propertyTemplates,
  reloadQuota,
  isUsageBlocked,
  propertyContents,
}) => {
  const [propertyContentsWithSourceData, setPropertyContentsWithSourceData] =
    useState<PropertyWithSourceData[]>([])
  useEffect(() => {
    const transformedData = propertyContents
      .map((obj) => {
        const sourceData = obj.ExtManagement || obj.ExtListing
        // filter out any ads that don't have a source
        // we can't click on them anyway
        if (!sourceData) return null
        const sourceType =
          sourceData === obj.ExtManagement ? 'management' : 'listing'

        return { ...obj, sourceData: { ...sourceData, sourceType } }
      })
      .filter(Boolean) as PropertyWithSourceData[]

    const cleanedData = transformedData.map((obj) => {
      const { ...rest } = obj
      return rest
    })
    setPropertyContentsWithSourceData(cleanedData)
  }, [propertyContents])

  return (
    <PropertyGenieMainPage
      propertyTemplates={propertyTemplates}
      propertyContents={propertyContentsWithSourceData}
      isUsageBlocked={isUsageBlocked}
      reloadQuota={reloadQuota}
    />
  )
}
