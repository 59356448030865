import * as React from 'react'

import ArrowDropDown from '@mui/lab/internal/svg-icons/ArrowDropDown'
import {
  Autocomplete,
  Box,
  InputAdornment,
  ListSubheader,
  Popper,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { ExtListing, ExtManagement } from 'types/graphql'

import { IntegrationLogo } from 'src/components/CustomerIntegrations/IntegrationCard'

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  }

  // eslint-disable-next-line no-prototype-builtins
  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <Box {...dataSet[0]} noWrap style={inlineStyle}>
      <IntegrationLogo size={'small'} integrationType={dataSet[3]} />
      <Typography className={'px-2'}> {`${dataSet[1]}`}</Typography>
    </Box>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    },
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child: React.ReactChild) => {
    // eslint-disable-next-line no-prototype-builtins
    if (child.hasOwnProperty('group')) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

export default function VirtualizedAutocomplete({
  options,
  onChange,
  icon = null,
  popupIcon = <ArrowDropDown />,
  label = '',
  disabled = false,
  value = null,
  dataTestId = '',
}) {
  const isOptionEqualToValue = (
    option: ExtManagement | ExtListing,
    value: ExtManagement | ExtListing,
  ) => {
    return option.id === value.id
  }

  return (
    <Autocomplete
      disableListWrap
      disabled={disabled}
      PopperComponent={StyledPopper}
      popupIcon={popupIcon}
      ListboxComponent={ListboxComponent}
      options={options}
      value={value}
      getOptionLabel={(option: ExtManagement | ExtListing) => option.address}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={(props, option, state) =>
        [
          props,
          option.address,
          state.index,
          option.integrationType,
        ] as React.ReactNode
      }
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disabled}
          placeholder={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            // Adjusting the icon position
            startAdornment: (
              <InputAdornment position="start" style={{ marginTop: '-5px' }}>
                {icon}
              </InputAdornment>
            ),
            style: {
              backgroundColor: 'white',
              borderRadius: '5px',
              height: '40px',
              paddingLeft: '15px',
            },
            inputProps: {
              ...params.inputProps,
              style: {
                marginTop: '-5px',
              },
            },
          }}
        />
      )}
      onChange={onChange}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
      data-testid={dataTestId}
    />
  )
}
