import React, { Dispatch, FC, SetStateAction } from 'react'

import {
  FindPropertyGenieListingFormQuery,
  FindPropertyGenieListingFormQueryVariables,
  PropertyTemplate,
} from 'types/graphql'

import { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import PropertyGenieListingForm from 'src/components/PropertyGenie/PropertyGenieListingForm'
import { themeType } from 'src/components/PropertyGenie/PropertyGenieMainPage'

export const QUERY = gql`
  query FindPropertyGenieListingFormQuery {
    extManagementsFilteredByActive {
      id
      address
      bedrooms
      bathrooms
      carSpaces
      landSize
      integrationType
    }
    extListingsFilteredByActive {
      id
      address
      bedrooms
      bathrooms
      carSpaces
      landSize
      integrationType
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty: FC<SuccessProps> = ({
  extManagementsFilteredByActive,
  extListingsFilteredByActive,
  propertyTemplates,
  updatedProperty,
  open,
  handleClose,
  generatePropertyContent,
  selectedKeywords,
  setSelectedKeywords,
  selectedThemes,
  sourceType,
  setSelectedThemes,
  setVersion,
  version,
  setSelectedContent,
  setOpenDrawer,
}) => {
  const combinedData = [
    ...extManagementsFilteredByActive.map((item) => ({
      ...item,
      address: item.address || 'No Address Found',
      type: 'management',
    })),
    ...extListingsFilteredByActive.map((item) => ({
      ...item,
      address: item.address || 'No Address Found',
      type: 'listing',
    })),
  ]

  return (
    <PropertyGenieListingForm
      sourceType={sourceType}
      setVersion={setVersion}
      version={version}
      propertyList={combinedData}
      propertyTemplates={propertyTemplates}
      updatedProperty={updatedProperty}
      open={open}
      handleClose={handleClose}
      generatePropertyContent={generatePropertyContent}
      selectedKeywords={selectedKeywords}
      setSelectedKeywords={setSelectedKeywords}
      selectedThemes={selectedThemes}
      setSelectedThemes={setSelectedThemes}
      setSelectedContent={setSelectedContent}
      setOpenDrawer={setOpenDrawer}
    />
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type SuccessProps = CellSuccessProps<
  FindPropertyGenieListingFormQuery,
  FindPropertyGenieListingFormQueryVariables
> & {
  updatedProperty: any
  propertyTemplates: PropertyTemplate[] | []
  open: boolean
  handleClose: () => void
  generatePropertyContent: (args: any) => any
  selectedKeywords: string[]
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>
  selectedThemes: themeType[]
  setSelectedThemes: Dispatch<SetStateAction<themeType[]>>
  sourceType: string
  setVersion: Dispatch<SetStateAction<number>>
  version: number
  setSelectedContent: Dispatch<SetStateAction<any>>
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
}

export const Success: FC<SuccessProps> = ({
  extManagementsFilteredByActive,
  extListingsFilteredByActive,
  propertyTemplates,
  updatedProperty,
  open,
  handleClose,
  generatePropertyContent,
  selectedKeywords,
  setSelectedKeywords,
  selectedThemes,
  sourceType,
  setSelectedThemes,
  setVersion,
  version,
  setSelectedContent,
  setOpenDrawer,
}) => {
  const combinedData = [
    ...extManagementsFilteredByActive.map((item) => ({
      ...item,
      address: item.address || 'No Address Found',
      type: 'management',
    })),
    ...extListingsFilteredByActive.map((item) => ({
      ...item,
      address: item.address || 'No Address Found',
      type: 'listing',
    })),
  ]

  return (
    <PropertyGenieListingForm
      sourceType={sourceType}
      setVersion={setVersion}
      version={version}
      propertyList={combinedData}
      propertyTemplates={propertyTemplates}
      updatedProperty={updatedProperty}
      open={open}
      handleClose={handleClose}
      generatePropertyContent={generatePropertyContent}
      selectedKeywords={selectedKeywords}
      setSelectedKeywords={setSelectedKeywords}
      selectedThemes={selectedThemes}
      setSelectedThemes={setSelectedThemes}
      setSelectedContent={setSelectedContent}
      setOpenDrawer={setOpenDrawer}
    />
  )
}
