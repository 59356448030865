const BathIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.724 15.75V14.25H6.224V15.75H4.724ZM13.724 15.75V14.25H15.224V15.75H13.724Z"
        fill="currentColor"
      />
      <path
        d="M12.599 6.75C12.8061 6.75 12.974 6.58211 12.974 6.375C12.974 6.16789 12.8061 6 12.599 6C12.3919 6 12.224 6.16789 12.224 6.375C12.224 6.58211 12.3919 6.75 12.599 6.75Z"
        fill="currentColor"
      />
      <path
        d="M11.099 6.75C11.3061 6.75 11.474 6.58211 11.474 6.375C11.474 6.16789 11.3061 6 11.099 6C10.8919 6 10.724 6.16789 10.724 6.375C10.724 6.58211 10.8919 6.75 11.099 6.75Z"
        fill="currentColor"
      />
      <path
        d="M9.599 6.75C9.80611 6.75 9.974 6.58211 9.974 6.375C9.974 6.16789 9.80611 6 9.599 6C9.39189 6 9.224 6.16789 9.224 6.375C9.224 6.58211 9.39189 6.75 9.599 6.75Z"
        fill="currentColor"
      />
      <path
        d="M11.099 5.25C11.3061 5.25 11.474 5.08211 11.474 4.875C11.474 4.66789 11.3061 4.5 11.099 4.5C10.8919 4.5 10.724 4.66789 10.724 4.875C10.724 5.08211 10.8919 5.25 11.099 5.25Z"
        fill="currentColor"
      />
      <path
        d="M11.099 8.25C11.3061 8.25 11.474 8.08211 11.474 7.875C11.474 7.66789 11.3061 7.5 11.099 7.5C10.8919 7.5 10.724 7.66789 10.724 7.875C10.724 8.08211 10.8919 8.25 11.099 8.25Z"
        fill="currentColor"
      />
      <path
        d="M9.599 8.25C9.80611 8.25 9.974 8.08211 9.974 7.875C9.974 7.66789 9.80611 7.5 9.599 7.5C9.39189 7.5 9.224 7.66789 9.224 7.875C9.224 8.08211 9.39189 8.25 9.599 8.25Z"
        fill="currentColor"
      />
      <path
        d="M14.099 8.25C14.3061 8.25 14.474 8.08211 14.474 7.875C14.474 7.66789 14.3061 7.5 14.099 7.5C13.8919 7.5 13.724 7.66789 13.724 7.875C13.724 8.08211 13.8919 8.25 14.099 8.25Z"
        fill="currentColor"
      />
      <path
        d="M12.599 8.25C12.8061 8.25 12.974 8.08211 12.974 7.875C12.974 7.66789 12.8061 7.5 12.599 7.5C12.3919 7.5 12.224 7.66789 12.224 7.875C12.224 8.08211 12.3919 8.25 12.599 8.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.224 3.75C5.82617 3.75 5.44464 3.90804 5.16334 4.18934C4.88203 4.47064 4.724 4.85218 4.724 5.25V9H16.724V11.25C16.724 12.4435 16.2499 13.5881 15.406 14.432C14.5621 15.2759 13.4175 15.75 12.224 15.75H7.724C6.53052 15.75 5.38593 15.2759 4.54202 14.432C3.6981 13.5881 3.224 12.4435 3.224 11.25V5.25C3.224 4.45435 3.54007 3.69129 4.10268 3.12868C4.66529 2.56607 5.42835 2.25 6.224 2.25H6.974V3.75H6.224ZM4.724 10.5V11.25C4.724 12.0456 5.04007 12.8087 5.60268 13.3713C6.16529 13.9339 6.92835 14.25 7.724 14.25H12.224C13.0196 14.25 13.7827 13.9339 14.3453 13.3713C14.9079 12.8087 15.224 12.0456 15.224 11.25V10.5H4.724Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82399 3.15006C9.89897 3.07662 9.95021 2.9824 9.97111 2.87955C9.99201 2.7767 9.98161 2.66995 9.94124 2.57308C9.90088 2.4762 9.8324 2.39365 9.74466 2.33607C9.65692 2.27849 9.55393 2.24853 9.44899 2.25006H7.72399C7.52508 2.25006 7.33432 2.32907 7.19366 2.46973C7.05301 2.61038 6.97399 2.80114 6.97399 3.00006V4.72506C6.97247 4.82999 7.00243 4.93298 7.06001 5.02072C7.11758 5.10847 7.20014 5.17694 7.29702 5.2173C7.39389 5.25767 7.50064 5.26807 7.60349 5.24717C7.70633 5.22627 7.80056 5.17503 7.87399 5.10006L8.23072 4.74333L6.97399 4.72506H8.24899L9.82399 3.15006Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default BathIcon
