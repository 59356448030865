import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentDuplicateIcon,
  FunnelIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { SparklesIcon } from '@heroicons/react/24/solid'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid2 as Grid,
  InputLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Select, { components } from 'react-select'
import {
  ExtManagement,
  UpdatePropertyContent,
  UpdatePropertyContentVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { IntegrationType } from 'src/components/CustomerIntegrations/integrations'
import Button from 'src/components/Library/Button'
import { default as EmptyData } from 'src/components/Library/Empty'
import PropertyGenerateModal from 'src/components/PropertyGenie/PropertyGenerateModal'
import { themeType } from 'src/components/PropertyGenie/PropertyGenieMainPage'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import BathIcon from 'src/lib/icons/BathIcon/BathIcon'
import BedIcon from 'src/lib/icons/BedIcon/BedIcon'
import CarIcon from 'src/lib/icons/CarIcon/CarIcon'
import { UPDATE_PROPERTY_CONTENT } from 'src/lib/queries/PropertyGenie/PropertyContent'
import { useAuth } from 'src/Providers'

import { PropertyTemplate } from './PropertyLocationsCell'

interface CustomProperty extends ExtManagement {
  pool: boolean
  petFriendly: boolean
}

interface StatusTypes {
  value: string
  label: string
  color: string
}

export interface PropertyGenieProps {
  property: ExtManagement | CustomProperty
  propertyTemplates: PropertyTemplate[]
  isUsageBlocked: boolean
  statusTypes: StatusTypes[]
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
  setOpenCreateAdModal: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
  generatePropertyContent: (property: ExtManagement) => void
  selectedKeywords: string[]
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>
  selectedThemes: themeType[]
  setSelectedThemes: Dispatch<SetStateAction<themeType[]>>
  openCreateAdModal: boolean
  contentId: number
  sourceType: string
  setSelectedContent: Dispatch<SetStateAction<any>>
}

const PropertyGenie: FC<PropertyGenieProps> = ({
  property,
  propertyTemplates,
  isUsageBlocked,
  statusTypes,
  setOpenDrawer,
  contentId,
  setOpenCreateAdModal,
  handleClose,
  generatePropertyContent,
  selectedKeywords,
  setSelectedKeywords,
  selectedThemes,
  setSelectedThemes,
  openCreateAdModal,
  sourceType,
  setSelectedContent,
}) => {
  const { currentUser } = useAuth()
  const { trackEvent } = useAnalytics()

  const [selectedFilter, setSelectedFilter] = useState<StatusTypes[]>(null)
  const [contentList, setContentList] = useState([])
  const [version, setVersion] = useState<number>(
    property.propertyContents?.length > 0
      ? property.propertyContents?.length - 1
      : 0,
  )
  const [currentStatus, setCurrentStatus] = useState<StatusTypes>(null)
  const [changingStatus, setChangingStatus] = useState<boolean>(false)
  const [viewCreateAdModal, setViewCreateAdModal] = useState<boolean>(false)
  const [updatedProperty, setUpdatedProperty] = useState<CustomProperty | null>(
    { ...property, pool: false, petFriendly: false },
  )

  const createdAtDate = new Date(property?.propertyContents[version]?.createdAt)
  const createdDateFormatted = property?.propertyContents[version]?.createdAt
    ? `${createdAtDate.getDate()}/${
        createdAtDate.getMonth() + 1
      }/${createdAtDate.getFullYear()}`
    : null
  const approvedDate = new Date(property?.propertyContents[version]?.approved)
  const approvedDateFormatted = property?.propertyContents[version]?.approved
    ? `${approvedDate.getDate()}/${
        approvedDate.getMonth() + 1
      }/${approvedDate.getFullYear()}`
    : null

  const [updatePropertyContentMutation] = useMutation<
    UpdatePropertyContent,
    UpdatePropertyContentVariables
  >(UPDATE_PROPERTY_CONTENT, {
    onCompleted: () => {
      toast.success('Updated', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['FindPropertyGenieQuery', 'PropertyLocationsQuery'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const handleFilter = (value: any) => {
    setSelectedFilter(value)
    setVersion(0)
    trackEvent('Ad Generator', 'Filter Ad Content')
  }

  const handleOpen = () => {
    trackEvent('Ad Generator', 'click Create New Ad')

    setUpdatedProperty({ ...property, pool: false, petFriendly: false })
    setOpenCreateAdModal(true)
    setViewCreateAdModal(true)
  }

  const handleChangeStatus = async (value: any) => {
    setChangingStatus(true)

    await updatePropertyContentMutation({
      variables: {
        id: property?.propertyContents?.[version]?.id,
        input: {
          status: value.value,
          approvedBy:
            value.value === 'APPROVED' ? currentUser.userData.id : null,
          approved:
            value.value === 'APPROVED' ? new Date().toISOString() : null,
        },
      },
    })

    setChangingStatus(false)

    trackEvent('Ad Generator', 'Change Ad Status', {
      status: value.value,
    })
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="fa fa-filter" aria-hidden="true"></i>
        <FunnelIcon className="h-5 w-5 text-gray-400" />{' '}
      </components.DropdownIndicator>
    )
  }

  const handleCopyDataToClipboard = () => {
    navigator.clipboard.writeText(contentList[version]?.content)
    toast.success('Copied', {
      duration: 2000,
      className: 'flex-column',
    })
    trackEvent('Ad Generator', 'Click Copy Ad Content')
  }

  useEffect(() => {
    if (selectedFilter && selectedFilter.length && property) {
      setContentList(
        property.propertyContents.filter((item) =>
          selectedFilter.some((filter) => item.status === filter.value),
        ),
      )
    } else {
      setContentList(property.propertyContents)
    }
  }, [selectedFilter, property])

  useEffect(() => {
    const statusObject = statusTypes.find(
      (status) => status.value === contentList?.[version]?.status,
    )
    setCurrentStatus(
      statusObject ? statusObject : contentList?.[version]?.status,
    )
  }, [property, statusTypes, version, selectedFilter, contentList])

  useEffect(() => {
    if (contentId && property) {
      const index = property.propertyContents.findIndex(
        (item) => item.id === contentId,
      )
      setVersion(index)
    }
  }, [contentId, property])

  return (
    <Box className="p-6">
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="flex-end"
        spacing={2}
        width={'100%'}
      >
        <XMarkIcon
          onClick={() => {
            setOpenDrawer(false)
          }}
          className={'h-6 w-6 text-gray-500 hover:cursor-pointer'}
        />
      </Stack>
      <Card
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        elevation={0}
      >
        <CardContent sx={{ width: '100%' }}>
          <Stack direction={'row'}>
            <Typography
              component="div"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
              data-testid={`ad-generator-address-title-${property.address}`}
            >
              {property.address}
            </Typography>

            <div className="flex flex-row items-center">
              {property?.integrationType === ('PME' as IntegrationType) && (
                <Tooltip title={'Property Sourced From PME'}>
                  <img
                    src="/logo-pme.png"
                    alt=""
                    className="ml-2 h-[15px] w-[15px]"
                  />
                </Tooltip>
              )}
            </div>
          </Stack>

          <div className="flex flex-row py-2">
            <div className="mr-1 flex flex-row items-center">
              <BedIcon className={'h-[15px] w-[15px] text-gray-300'} />
              <div className="ml-1 text-xs text-gray-800">
                {property.bedrooms || '-'}
              </div>
            </div>
            <div className="mr-1 flex flex-row items-center">
              <BathIcon className={'h-[15px] w-[15px] text-gray-300'} />
              <div className="ml-1 text-xs text-gray-800">
                {property.bathrooms || '-'}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <CarIcon className={'h-[15px] w-[15px] text-gray-300'} />
              <div className="ml-1 text-xs text-gray-800">
                {property.carSpaces || '-'}
              </div>
            </div>
          </div>

          <div>
            {property?.propertyContents?.[version]?.keywords && (
              <>
                {property?.propertyContents?.[version]?.keywords
                  ?.split('&')
                  .map((item, index) => (
                    <Chip
                      key={index}
                      className="ml-1 text-xs text-gray-800"
                      label={item}
                      style={{
                        fontSize: '0.7rem',
                        padding: '2px 2px',
                        height: 'auto',
                      }}
                    />
                  ))}
              </>
            )}
          </div>
          <div>
            {createdDateFormatted && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '5px',
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <Typography style={{ fontSize: '0.7rem' }}>
                    Created
                  </Typography>
                  <Typography style={{ fontSize: '0.7rem' }}>
                    {createdDateFormatted}
                  </Typography>
                  <Typography style={{ fontSize: '0.7rem' }}>By</Typography>
                  <Typography style={{ fontSize: '0.7rem' }}>
                    {property?.propertyContents?.[version]?.createdByUser?.name}
                  </Typography>
                </div>
              </div>
            )}
            {approvedDateFormatted && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '5px',
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <Typography style={{ fontSize: '0.7rem' }}>
                    Approved
                  </Typography>
                  <Typography style={{ fontSize: '0.7rem' }}>
                    {approvedDateFormatted}
                  </Typography>
                  <Typography style={{ fontSize: '0.7rem' }}>By</Typography>
                  <Typography style={{ fontSize: '0.7rem' }}>
                    {
                      property?.propertyContents?.[version]?.approvedByUser
                        ?.name
                    }
                  </Typography>
                </div>
              </div>
            )}
            {property?.propertyContents?.[version]?.template && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '5px',
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <Typography style={{ fontSize: '0.7rem' }}>
                    Template
                  </Typography>
                  <Typography style={{ fontSize: '0.7rem' }}>
                    {property?.propertyContents?.[version]?.template?.name ||
                      'None'}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </CardContent>
        <Stack>
          <CardMedia
            component="img"
            sx={{ width: 325, height: 112 }}
            image={property.propertyImageUrl}
            alt=""
            className="rounded-lg"
          />
          <Button
            startIcon={<SparklesIcon className="h-5 w-5" />}
            className="mt-5 min-w-[0] rounded-lg"
            variant="contained"
            fullWidth={false}
            disabled={isUsageBlocked}
            onClick={handleOpen}
            buttonDataTestId="open-listing-modal-button"
          >
            Create New
          </Button>
        </Stack>
      </Card>
      <Stack direction="row" alignItems="center" spacing={2} width={'100%'}>
        <PropertyGenerateModal
          sourceType={sourceType}
          openCreateAdModal={openCreateAdModal}
          propertyTemplates={propertyTemplates}
          handleClose={handleClose}
          generatePropertyContent={generatePropertyContent}
          selectedKeywords={selectedKeywords}
          setSelectedKeywords={setSelectedKeywords}
          selectedThemes={selectedThemes}
          setSelectedThemes={setSelectedThemes}
          viewCreateAdModal={viewCreateAdModal}
          updatedProperty={updatedProperty}
          setVersion={setVersion}
          version={version}
          setSelectedContent={setSelectedContent}
          setOpenDrawer={setOpenDrawer}
        />
      </Stack>

      {property.propertyContents && property.propertyContents?.length > 0 && (
        <Grid container alignItems="center" spacing={2} className={'my-2'}>
          <Grid size={{ xs: 3 }}>
            <ButtonGroup
              aria-label="large button group"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                startIcon={<ChevronLeftIcon className={'ml-2 h-4 w-4'} />}
                key="one"
                size={'small'}
                fullWidth={false}
                variant="outlined"
                onClick={() => {
                  if (contentList.length <= 1) return

                  if (version === 0) {
                    setVersion(contentList.length - 1)
                  } else {
                    setVersion(version - 1)
                  }

                  trackEvent('Ad Generator', 'Change Ad Version Left')
                }}
              />
              <Button
                variant="outlined"
                key="two"
                className={
                  'leading-3 text-indigo-800 hover:cursor-default hover:bg-transparent'
                }
              >
                {contentList.length > 0 ? version + 1 : 0}/{contentList.length}
              </Button>
              <Button
                startIcon={<ChevronRightIcon className={'ml-2 h-4 w-4'} />}
                key="three"
                size={'small'}
                fullWidth={false}
                variant="outlined"
                onClick={() => {
                  if (contentList.length <= 1) return
                  if (version === contentList.length - 1) {
                    setVersion(0)
                  } else {
                    setVersion(version + 1)
                  }
                  trackEvent('Ad Generator', 'Change Ad Version Right')
                }}
              />
            </ButtonGroup>
          </Grid>

          <Grid size={{ xs: 4 }}>
            <Select
              className="w-full"
              value={currentStatus}
              isDisabled={changingStatus || !contentList[version]?.content}
              isLoading={changingStatus}
              placeholder="Status"
              onChange={handleChangeStatus}
              options={statusTypes}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  height: '40px',
                  paddingLeft: '15px',
                }),
              }}
            />
          </Grid>
          <Grid size={{ xs: 5 }}>
            <Select
              className="w-full"
              value={selectedFilter}
              isMulti
              placeholder="Filter"
              onChange={handleFilter}
              options={statusTypes}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  height: '40px',
                  paddingLeft: '15px',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }),
                multiValue: (provided) => ({
                  ...provided,
                  maxHeight: '100px', // Adjust this value as needed
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }),
              }}
              components={{
                DropdownIndicator: DropdownIndicator,
              }}
            />
          </Grid>
        </Grid>
      )}

      {property.propertyContents &&
      property.propertyContents?.length > 0 &&
      contentList[version]?.content ? (
        <div className="mt-4">
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Stack
            direction={'row'}
            spacing={1}
            sx={{ marginTop: 2, marginBottom: 2, alignItems: 'center' }}
          >
            <InputLabel sx={{ fontSize: '12px' }}>CONTENT</InputLabel>

            <Tooltip title={'Copy Ad Content'}>
              <div>
                <Button
                  fullWidth={false}
                  variant="outlined"
                  className="min-w-[0] p-1"
                  onClick={handleCopyDataToClipboard}
                >
                  <DocumentDuplicateIcon className="h-3 w-3" />
                </Button>
              </div>
            </Tooltip>
          </Stack>
          <TextareaAutosize
            minRows={18}
            value={
              contentList && contentList?.length > 0 && contentList[version]
                ? contentList[version]?.content
                : ''
            }
            className={
              'max-h-[60vh] min-h-[60vh] w-full overflow-y-auto rounded border-2 border-gray-300 p-4'
            }
            data-testid="property-genie-content"
          />
        </div>
      ) : (
        <div className={'mt-4'}>
          <EmptyData title="No Generated Content Found" />
        </div>
      )}
    </Box>
  )
}

export default PropertyGenie
