const BedIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12V13.5H15V12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9H6ZM2.81802 8.81802C3.66193 7.97411 4.80653 7.5 6 7.5H12C13.1935 7.5 14.3381 7.97411 15.182 8.81802C16.0259 9.66193 16.5 10.8065 16.5 12V15H15.75V15.75H14.25V15H3.75002V15.75H2.25002V15H1.5V12C1.5 10.8065 1.97411 9.66193 2.81802 8.81802Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18182 5.625C4.88928 5.625 4.61578 5.72972 4.41992 5.9056C4.22538 6.08028 4.125 6.308 4.125 6.53571V8.25H3.375V6.53571C3.375 6.08144 3.57632 5.65511 3.91882 5.34756C4.26 5.0412 4.71487 4.875 5.18182 4.875H7.09091C7.55786 4.875 8.01273 5.0412 8.3539 5.34756C8.69641 5.65511 8.89773 6.08144 8.89773 6.53571V7.39286H8.14773V6.53571C8.14773 6.308 8.04734 6.08028 7.85281 5.9056C7.65695 5.72972 7.38344 5.625 7.09091 5.625H5.18182ZM10.9091 5.625C10.6166 5.625 10.3431 5.72972 10.1472 5.9056C9.95266 6.08028 9.85227 6.308 9.85227 6.53571V7.39286H9.10227V6.53571C9.10227 6.08144 9.30359 5.65511 9.6461 5.34756C9.98727 5.0412 10.4421 4.875 10.9091 4.875H12.8182C13.2851 4.875 13.74 5.0412 14.0812 5.34756C14.4237 5.65511 14.625 6.08144 14.625 6.53571V8.25H13.875V6.53571C13.875 6.308 13.7746 6.08028 13.5801 5.9056C13.3842 5.72972 13.1107 5.625 12.8182 5.625H10.9091Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.90901 2.15901C3.33097 1.73705 3.90326 1.5 4.5 1.5H13.5C14.0967 1.5 14.669 1.73705 15.091 2.15901C15.5129 2.58097 15.75 3.15326 15.75 3.75V9.75H14.25V3.75C14.25 3.55109 14.171 3.36032 14.0303 3.21967C13.8897 3.07902 13.6989 3 13.5 3H4.5C4.30109 3 4.11032 3.07902 3.96967 3.21967C3.82902 3.36032 3.75 3.55109 3.75 3.75V9.75H2.25V3.75C2.25 3.15326 2.48705 2.58097 2.90901 2.15901Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.375 13.125H2.625V12.375H15.375V13.125Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default BedIcon
