import * as React from 'react'
import { useState } from 'react'

import { Grid2 as Grid } from '@mui/material'
import { FLAG_FEATURES, HUBSPOT_FORM_ID } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import Tab from 'src/components/Library/Tab/Tab'
import Tabs from 'src/components/Library/Tabs/Tabs'
import PageHeader from 'src/components/PageHeader/PageHeader'
import PropertyLocationsCell from 'src/components/PropertyGenie/PropertyLocationsCell'
import PropertyTemplatesCell from 'src/components/PropertyGenie/PropertyTemplatesCell'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import useQuota from 'src/lib/hooks/Quota/useQuota'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'web/src/Providers'

const PropertyGeniePage = () => {
  const { trackEvent } = useAnalytics()
  const [value, setValue] = useState(0)
  const { hasRole } = useAuth()
  const isEditorAccess = hasRole(['ADMIN', 'OWNER', 'EDITOR', 'SUPERADMIN'])

  const { QuotaComponent, isUsageBlocked, reloadQuota } = useQuota({
    feature: FLAG_FEATURES.PROPERTY_GENIE,
  })

  const handleChange = (_, newValue: number) => {
    setValue(newValue)
    trackEvent('Ad Generator', 'change tabs', {
      newTab: newValue === 0 ? 'Ads' : 'Templates',
    })
  }

  return (
    <>
      <Metadata title="Ad Generator" description="Ad Generator" />
      <div className={'flex h-screen flex-col'}>
        <PageHeader
          title={'Ad Generator'}
          parentDataTestId="ad-gen-page-header"
        />
        <FeatureToggle
          feature={FLAG_FEATURES.PROPERTY_GENIE}
          InactiveComponent={
            <FeatureDenied hubspotFormId={HUBSPOT_FORM_ID.PROPERTY_GENIE} />
          }
        >
          <div className="flex h-full flex-col overflow-y-auto">
            <Grid
              className={'bg-white'}
              container
              spacing={2}
              style={{
                paddingTop: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                borderBottom: '1px solid #e0e0e0',

                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Grid size={{ xs: 6 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{ borderBottom: 'none' }}
                >
                  <Tab label="Ads" />
                  {isEditorAccess && <Tab label="Templates" />}
                </Tabs>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <QuotaComponent />
              </Grid>
            </Grid>
            <div className="flex-grow overflow-hidden">
              {value === 0 && (
                <PropertyLocationsCell
                  isUsageBlocked={isUsageBlocked}
                  reloadQuota={reloadQuota}
                />
              )}
              {value === 1 && <PropertyTemplatesCell />}
            </div>
            <div id="map"></div>
          </div>
        </FeatureToggle>
      </div>
    </>
  )
}

export default PropertyGeniePage
