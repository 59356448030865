/// <reference types="@types/google.maps" />

import { captureException } from '@sentry/browser'
import 'react'
import { useScript } from 'usehooks-ts'

declare global {
  interface Window {
    google: typeof google
  }
}

const useGoogleMaps = (props?: {
  removeOnUnmount?: boolean
  googleMapsApiKey?: string
}) => {
  const {
    removeOnUnmount = false,
    googleMapsApiKey = process.env.GOOGLE_MAPS_CLIENT_API_KEY,
  } = props ?? {}

  if (!googleMapsApiKey) {
    const error = new Error('Google Maps API key is required')
    captureException(error)
    throw error
  }

  const _status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places,geometry`,
    { removeOnUnmount },
  )

  return {
    google: window.google,
    googleMaps: window.google?.maps as typeof google.maps,

    _status,
    mapsIsReady: _status === 'ready' && typeof google !== 'undefined',
    mapsHasError: _status === 'error',
  }
}

export default useGoogleMaps
