const CarIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00201 6.75H4.50201V8.25H3.00201V6.75ZM15.002 6.75H16.502V8.25H15.002V6.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.627 8.25H4.87701V7.5H14.627V8.25ZM11.627 12H7.87701V11.25H11.627V12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6589 8.17968L12.4791 5.26945L12.4718 5.25L12.4619 5.25013H7.0421L7.03219 5.25L7.02493 5.26945L5.8451 8.17968L4.59413 10.2125C4.5336 10.3231 4.50188 10.4472 4.50202 10.5735L4.50202 10.5751L4.50202 12.7501H15.002V10.5735C15.0022 10.4472 14.9704 10.3231 14.9099 10.2125L13.6589 8.17968ZM16.202 9.45013L15.002 7.50013L13.877 4.72513C13.7689 4.43561 13.5739 4.18656 13.3189 4.01204C13.0638 3.83752 12.761 3.74605 12.452 3.75013H7.05202C6.74298 3.74605 6.44023 3.83752 6.18516 4.01204C5.9301 4.18656 5.73516 4.43561 5.62702 4.72513L4.50202 7.50013L3.30202 9.45013C3.10504 9.79231 3.00158 10.1803 3.00202 10.5751V12.7501C3.00202 13.148 3.16005 13.5295 3.44136 13.8108C3.72266 14.0921 4.10419 14.2501 4.50202 14.2501H15.002C15.3998 14.2501 15.7814 14.0921 16.0627 13.8108C16.344 13.5295 16.502 13.148 16.502 12.7501V10.5751C16.5025 10.1803 16.399 9.79231 16.202 9.45013Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75201 14.25H6.75201V15C6.75201 15.3978 6.59398 15.7794 6.31267 16.0607C6.03137 16.342 5.64984 16.5 5.25201 16.5C4.85419 16.5 4.47266 16.342 4.19135 16.0607C3.91005 15.7794 3.75201 15.3978 3.75201 15V14.25ZM12.752 14.25H15.752V15C15.752 15.3978 15.594 15.7794 15.3127 16.0607C15.0314 16.342 14.6498 16.5 14.252 16.5C13.8542 16.5 13.4727 16.342 13.1914 16.0607C12.91 15.7794 12.752 15.3978 12.752 15V14.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00201 10.5H6.00201C6.20093 10.5 6.39169 10.579 6.53234 10.7197C6.673 10.8603 6.75201 11.0511 6.75201 11.25V11.625C6.75201 11.9234 6.63349 12.2095 6.42251 12.4205C6.21153 12.6315 5.92538 12.75 5.62701 12.75H3.00201V10.5ZM3.75201 11.25V12H5.62701C5.72647 12 5.82185 11.9605 5.89218 11.8902C5.96251 11.8198 6.00201 11.7245 6.00201 11.625V11.25H3.75201ZM12.9717 10.7197C13.1123 10.579 13.3031 10.5 13.502 10.5H16.502V12.75H13.877C13.5786 12.75 13.2925 12.6315 13.0815 12.4205C12.8705 12.2095 12.752 11.9234 12.752 11.625V11.25C12.752 11.0511 12.831 10.8603 12.9717 10.7197ZM15.752 11.25L13.502 11.25V11.625C13.502 11.7245 13.5415 11.8198 13.6118 11.8902C13.6822 11.9605 13.7776 12 13.877 12H15.752V11.25Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default CarIcon
